"use client";

import { useStoreState } from "src/hooks/storeHooks";
import useSWR from "swr";
import store from "../store";
import { SupabaseUtils } from "@/lib/supabase/supabaseUtils";
import { createClient } from "@/lib/supabase/client";
import ProductsGrid from "@/components/studios/ProductsGrid";
import { JoinedProduct } from "@/types/supabase-custom/JoinedProduct";
import { joinedProductFields, ProductStatus } from "@/lib/utils/productUtils";

const supabaseUtils = new SupabaseUtils(createClient());

export default function StudioPage() {
  const tenant = useStoreState((state) => state.tenant);
  const tenantId = tenant?.id || "";
  const { data: products = [] } = useSWR(
    ["products"],
    async () => {
      // TODO - Move this to Supabase's DB ?
      if (tenantId) {
        const result = await supabaseUtils.getProducts<JoinedProduct>({
          fields: joinedProductFields,
          tenantId,
          status: ProductStatus.enabled,
        });

        if (result.isErr()) {
          store.getActions().setError({
            code: "" + result.error.code,
            message: "" + result.error.message,
          });
          return [];
        }
        return result.value[0];
      }
    },
    { revalidateOnFocus: false }
  );
  return (
    <div>
      <div></div>
      <ProductsGrid products={products as JoinedProduct[]} />
    </div>
  );
}
