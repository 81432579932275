import { LabelCustomization } from "@/types/supabase-custom/Customization";
import { flow, matchesProperty, property } from "lodash";
import fp from "lodash/fp";
import labelsPricing from "@/config/labelsPricingConfig";
import { JoinedConfiguration } from "@/types/supabase-custom/JoinedConfiguration";
import consts from "@/config/consts";
import { Tables } from "@/types";

export enum ProductStatus {
  enabled = "enabled",
  disabled = "disabled",
  draft = "draft",
}

export type WeightPerSize = {
  size: string;
  weight: number;
};

export type SizeSurcharge = {
  size: string;
  surcharge: number;
};

export type PriceThreshold = {
  cogs: number;
  price: number;
  leadTime: number;
  threshold: number;
};

export const joinedProductFields = `
  *,
  brands (
    *
  ),
  price_modifiers_assignments (
    *, 
    price_modifiers (
      *, 
      price_modifiers_components ( * ) 
    ) 
  ),
  price_lists (
    *,
    price_lists_components (
      *
    )
  )
`;

// export function getAvailableColors(handle: string) {
//   const productConfig = productsConfig[handle];
//   const customColors = find(colorsPricing, matchesProperty("category", "custom"))?.colors || [];

//   return concat(productConfig.predevelopedColorsConfig.colors, customColors);
// }

// export function getProductImageUrl(product: Tables<"products">, illustration: boolean, orientation: OrientationType) {
//   const productConfig = productsConfig[product.handle as string];

//   if (illustration) {
//     const url = productConfig?.illustrationImage;
//     return url || "";
//   }

//   if (orientation == OrientationType.Label) {
//     return productConfig.zoomedImage || "";
//   }

//   const url = productConfig.images[orientation];
//   return url || "";
// }

export function getLabelImageUrl(labelMetadata: LabelCustomization | null) {
  if (!labelMetadata) return "";

  return flow(
    fp.find(matchesProperty("type", labelMetadata.type)),
    property<(typeof labelsPricing)[0]["label_images"], string>(`label_images.${labelMetadata.stitch}`)
  )(labelsPricing);
}

export function getCustomImagesUrls(configuration: JoinedConfiguration | Tables<"configurations">): string[] {
  return Object.entries(configuration)
    .filter(([key, value]) => key.endsWith(consts.CUSTOM_IMAGE_URL_SUFFIX) && value !== null)
    .map(([_, value]) => value as string);
}
